import type { ComponentTag } from '@gem/control';
import type { OptionSelect } from '@gem/uikit';
import type { ControlType, TriggerEvent } from '../constants';

export type RollBackEvent<E = string, Data = unknown> = {
  event: E;
  data?: Data;
};

export type InteractionElement<E, T> = {
  uid?: string;
  event?: E | string;
  condition?: T;
  elementTag?: ComponentTag;
  targetOutsideSelector?: string;
  targetInsideSelector?: string;
  isTriggerListenerForChildren?: boolean;
  id?: string;
  rollbackEvent?: RollBackEvent;
  productId?: string;
  articleId?: string;
  controlType?: ControlType;
  sectionId?: string;
  type?: 'PAGE' | 'ELEMENT';
};

export enum InteractionTriggerEventRollback {
  'mouseout',
  'gp:rollback:appear',
  'gp:rollback:item-selected',
  'gp:rollback:item-unselected',
  'gp:rollback:item-click',
  'gp:rollback:after-submit',
}

export type InteractionTargetEvent =
  | 'gp:change-background-color'
  | 'gp:run-animation'
  | 'gp:toggle-element-visibility'
  | 'gp:change-text'
  | 'gp:scroll-to'
  | 'gp:change-text-style'
  | 'gp:change-content'
  | 'gp:change-button-style'
  | 'gp:copy-text'
  | 'gp:change-image'
  | 'gp:toggle-video-play'
  | 'gp:change-slider-ratio'
  | 'gp:change-icon-style'
  | 'gp:change-expanded-item'
  | 'gp:change-active-item'
  | 'gp:change-slide'
  | 'gp:change-image-step'
  | 'gp:change-text-value'
  | 'gp:toggle-popup-open'
  | 'gp:show-or-hide'
  | 'gp:change-color-shape'
  | 'gp:change-banner'
  | 'gp:change-next-page'
  | 'gp:toggle-popup-close'
  | 'gp:change-next-slide'
  | 'gp:change-previous-slide'
  | 'gp:change-open-tab';

export enum InteractionTriggerEvent {
  'click',
  active,
  'mouseenter',
  'gp:appear',
  'gp:item-selected',
  'gp:item-unselected',
  'gp:item-hovered',
  'gp:item-click',
  'gp:after-submit',
  'gp:quantity-change',
  'gp:after-checked-value',
  'gp:after-add-to-cart',
  'gp:popup-open',
  'gp:popup-close',
  'gp:scroll-up',
  'gp:scroll-down',
  'gp:distance',
  'gp:delay',
  'gp:variant-hovered',
  'gp:variant-clicked',
  'gp:variant-selected',
  'gp:compare-price-change',
  'gp:price-change',
  'gp:stock-change',
  'gp:out-of-stock',
  'gp:select-slide',
  'gp:tab-active',
  'gp:expand-item',
}

export enum ConditionQuantity {
  EQUAL = 'equal',
  GREATER = 'greater',
  LESS = 'less',
  GREATER_OR_EQUAL = 'greater_or_equal',
  LESS_OR_EQUAL = 'less_or_equal',
  BETWEEN = 'between',
  OUT_OF_STOCK = 'out_of_stock',
  OUT_OF_TIME = 'out_of_time',
}

export type InteractionConditionData = {
  amount: string;
  enabled: boolean;
};

export type ValueMappingKeys = {
  mappingKeys: (string | number)[];
  value: any;
};

export enum InteractionConditionType {
  'equal',
  'greater',
  'less',
  'greater_or_equal',
  'less_or_equal',
  'distance_to_top',
  'selected_item',
  'selected_variant',
  'between',
  'out_of_stock',
  'out_of_time',
  'delay',
}

export type InteractionCondition = {
  type?: keyof typeof InteractionConditionType;
  data?: any;
  metaData?: any;
  optionName?: string;
};
export type Interaction = {
  id?: string;
  self?: InteractionElement<TriggerEvent, InteractionCondition>;
  targets?: InteractionTarget[];
};

export type InteractionTrigger = InteractionElement<TriggerEvent, InteractionCondition>;

export type InteractionTarget = {
  uid?: string;
  elementTag?: ComponentTag;
  targetOutsideSelector?: string;
  targetInsideSelector?: string;
  events?: InteractionTargetEventObject[];
  sectionId?: string;
  id?: string;
  type?: 'PAGE' | 'ELEMENT';
  productId?: string;
  articleId?: string;
};

export type InteractionTargetEventObject = {
  event: InteractionTargetEvent;
  eventName: string;
  condition?: InteractionCondition;
  hasCondition?: boolean;
  controlId: string;
};

export type InteractionsGroupByTrigger = {
  elementTag?: ComponentTag;
  uid?: string;
  list?: Interaction[];
  type?: 'PAGE' | 'ELEMENT';
  productId?: string;
  sectionId?: string;
  articleId?: string;
};

export type TargetEventOption = OptionSelect & {
  hasCondition?: boolean;
  defaultCondition?: InteractionCondition;
};
